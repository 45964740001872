import Cookies from "js-cookie";

const launchGA = () => {
  var s = document.createElement("script");
  s.src = "https://www.googletagmanager.com/gtag/js?id=UA-175440986-1";
  s.async = 1;
  document.getElementsByTagName("head")[0].appendChild(s);

  window.dataLayer = window.dataLayer || [];
  window.gtag = function () {
    dataLayer.push(arguments);
  };
  gtag("js", new Date());

  gtag("config", "UA-175440986-1");
  gtag("config", "AW-568076548", { send_page_view: false });
};

const launchHJ = () => {
  (function (h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function () {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: 2705870, hjsv: 6 };
    a = o.getElementsByTagName("head")[0];
    r = o.createElement("script");
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
};

const buildBase = () => {
  launchGA();
};

const buildScripts = () => {
  const consent = Cookies.get("CONSENT");
  let categories = {};
  if (consent === undefined) {
    categories = {
      analytics: false,
      ads: false,
    };
  } else {
    categories = JSON.parse(consent).categories;
  }

  if (categories.analytics) {
    launchHJ();
  }
};

$(document).on("cookies:defined", (event) => {
  if (process.env.NODE_ENV === "production") {
    buildScripts();
  }
});

$(() => {
  if (process.env.NODE_ENV === "production") {
    buildBase();
    buildScripts();
  }
});
