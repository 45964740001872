import Swiper, { Navigation, FreeMode, Mousewheel } from "swiper";

$(() => {
  if ($("body").hasClass("properties") && $("body").hasClass("archived")) {
    Swiper.use([Navigation, FreeMode, Mousewheel]);

    const buildLatestPropertiesSlider = () => {
      new Swiper("section.latest-properties .swiper", {
        slidesPerView: 1.5,
        spaceBetween: 20,
        freeMode: true,
        mousewheel: {
          forceToAxis: true,
        },
        navigation: {
          nextEl: "section.latest-properties .nav-btn-next",
          prevEl: "section.latest-properties .nav-btn-prev",
        },
        breakpoints: {
          768: {
            slidesPerView: 2.5,
          },
          992: {
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
        },
      });
    };

    buildLatestPropertiesSlider();
  }
});
