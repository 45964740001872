$(() => {
  if ($("body").hasClass("sell") && $("body").hasClass("index")) {
    const $sellForm = $("form.sell-form");
    const $sellFormBtn = $sellForm.find(".btn-submit");

    const buildSellForm = () => {
      $sellForm.validate({
        ignoreTitle: true,
        rules: {
          "sell_lead[city]": { required: true },
          "sell_lead[developer]": { required: true },
          "sell_lead[development]": { required: true },
          "sell_lead[delivery]": { required: true },
          "sell_lead[prop_type]": { required: true },
          "sell_lead[num_rooms]": { required: true },
          "sell_lead[first_name]": { required: true },
          "sell_lead[last_name]": { required: true },
          "sell_lead[email]": { required: true, email: true },
          "sell_lead[phone]": { required: true },
        },
        messages: {
          "sell_lead[city]": {
            required: "Veuillez saisir la ville",
          },
          "sell_lead[developer]": {
            required: "Veuillez saisir le promoteur",
          },
          "sell_lead[development]": {
            required: "Veuillez saisir le nom du programme",
          },
          "sell_lead[delivery]": {
            required: "Veuillez saisir la livraison",
          },
          "sell_lead[prop_type]": {
            required: "Veuillez saisir le type du bien",
          },
          "sell_lead[num_rooms]": {
            required: "Veuillez saisir le nombre de pièces",
          },
          "sell_lead[first_name]": {
            required: "Veuillez saisir votre prénom",
          },
          "sell_lead[last_name]": {
            required: "Veuillez saisir votre nom",
          },
          "sell_lead[email]": {
            required: "Veuillez saisir votre e-mail",
            email: "E-mail non valide",
          },
          "sell_lead[phone]": {
            required: "Veuillez saisir votre téléphone",
          },
        },
        errorElement: "label",
        errorPlacement: (error, element) => {
          var fg = $(element).closest(".form-group");
          $(fg).addClass("has-error");
          $(fg).append(error);
        },
        success: (label, element) => {
          var fg = $(element).closest(".form-group");
          $(fg).removeClass("has-error");
        },
        submitHandler: (form) => {
          $sellForm.find(".error-msg").html("");
          $sellFormBtn.addClass("loading").attr("disabled", true);
          form.submit();
        },
        invalidHandler: (event, validator) => {
          $sellFormBtn.removeClass("loading").attr("disabled", false);
        },
      });

      // Delivery
      const $deliverySelectWrapper = $sellForm.find(".delivery-select-wrapper");
      const $deliverySelect = $deliverySelectWrapper.find(".select2");
      const deliverySelect2 = $deliverySelect
        .select2({
          minimumResultsForSearch: Infinity,
          placeholder: "Choisir",
          dropdownParent: $deliverySelectWrapper,
          allowClear: true,
        })
        .on("select2:unselecting", (event) => {
          $(event.currentTarget).data("unselecting", true);
        })
        .on("select2:opening", (event) => {
          const $this = $(event.currentTarget);
          if ($this.data("unselecting")) {
            $this.removeData("unselecting");
            event.preventDefault();
          }
        });

      deliverySelect2
        .data("select2")
        .$container.find(".select2-selection")
        .removeClass("select2-selection--single")
        .addClass("custom-select");

      $deliverySelect.on("change", (event) => {
        const $this = $(event.currentTarget);
        $this.closest(".form-group").addClass("has-error");
        $this.valid();
      });

      // Prop Type
      const $propTypeSelectWrapper = $sellForm.find(
        ".prop-type-select-wrapper"
      );
      const $propTypeSelect = $propTypeSelectWrapper.find(".select2");
      const propTypeSelect2 = $propTypeSelect
        .select2({
          minimumResultsForSearch: Infinity,
          placeholder: "Choisir",
          dropdownParent: $propTypeSelectWrapper,
          allowClear: true,
        })
        .on("select2:unselecting", (event) => {
          $(event.currentTarget).data("unselecting", true);
        })
        .on("select2:opening", (event) => {
          const $this = $(event.currentTarget);
          if ($this.data("unselecting")) {
            $this.removeData("unselecting");
            event.preventDefault();
          }
        });

      propTypeSelect2
        .data("select2")
        .$container.find(".select2-selection")
        .removeClass("select2-selection--single")
        .addClass("custom-select");

      $propTypeSelect.on("change", (event) => {
        const $this = $(event.currentTarget);
        $this.closest(".form-group").addClass("has-error");
        $this.valid();
      });

      // Num rooms
      new Cleave("#sell_lead_num_rooms", {
        numeral: true,
        numeralDecimalMark: "",
        delimiter: "",
        numeralPositiveOnly: true,
      });

      $sellForm.removeClass("d-none");
    };

    buildSellForm();

    if (sellLeadCreated == "true") {
      bootbox.alert({
        className: "bootbox-modal bootbox-alert",
        centerVertical: true,
        backdrop: true,
        message: `<div class="text-center">
                    <div class="fs-2">
                      <i class="mdi mdi-check-circle text-success"></i>
                    </div>
                    <h3 class="fs-4 mb-2">Demande envoyée</h3>
                    <p>Nous avons bien reçu votre demande.<br>Un consultant de Revente-VEFA vous contactera rapidement.</p>
                  </div>`,
        closeButton: false,
        buttons: {
          ok: {
            label: "OK",
            className: "btn-secondary px-5 mx-auto mb-2",
          },
        },
      });

      if (typeof gtag === "function") {
        gtag("event", "conversion", {
          send_to: "AW-568076548/1kL0CPnIgYQDEITS8I4C",
        });
      }
    }
  }
});
