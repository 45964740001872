// Rails
require("@rails/ujs").start();

// Bootstrap
require("bootstrap/dist/js/bootstrap");

// JQuery Validation
require("jquery-validation/dist/jquery.validate");
require("jquery-validation/dist/localization/messages_fr");

// Lazysizes
require("lazysizes/lazysizes");
require("lazysizes/plugins/unveilhooks/ls.unveilhooks");
require("lazysizes/plugins/print/ls.print");

// Select2
require("select2/dist/js/select2.full");

// Toastr
global.toastr = require("toastr");
require("../front/config/toastr");

// Bootbox
global.bootbox = require("../vendors/bootbox/bootbox.min");

// Cleave
require("cleave.js/dist/cleave");

// Sentry
require("../front/src/modules/sentry");

// RGPD
require("../front/src/modules/scripts");
require("../front/src/modules/consent");

// Pages
require("../front/src/pages/all");
require("../front/src/properties/all");
require("../front/src/alerts/index");
require("../front/src/contact/index");
require("../front/src/sell/index");
require("../front/src/blog/all");
