$(() => {
  if ($("body").hasClass("alerts") && $("body").hasClass("index")) {
    const $form = $("form.alert-form");
    const $submitBtn = $form.find(".btn-submit");

    const buildAlertForm = () => {
      $form.validate({
        ignoreTitle: true,
        rules: {
          "alert[first_name]": { required: true },
          "alert[last_name]": { required: true },
          "alert[email]": { email: true, required: true },
          "alert[phone]": { required: true },
        },
        messages: {
          "alert[first_name]": {
            required: "Prénom obligatoire",
          },
          "alert[last_name]": {
            required: "Nom obligatoire",
          },
          "alert[email]": {
            email: "E-mail invalide",
            required: "E-mail obligatoire",
          },
          "alert[phone]": {
            required: "Téléphone obligatoire",
          },
        },
        errorElement: "label",
        errorPlacement: (error, element) => {
          var fg = $(element).closest(".form-group");
          $(fg).addClass("has-error");
          $(fg).append(error);
        },
        success: (label, element) => {
          var fg = $(element).closest(".form-group");
          $(fg).removeClass("has-error");
        },
        submitHandler: (form) => {
          $submitBtn.addClass("loading").attr("disabled", true);
          form.submit();
        },
        invalidHandler: (event, validator) => {
          $submitBtn.removeClass("loading").attr("disabled", false);
        },
      });

      // Places
      const $placePidsWrapper = $(".place-pids-wrapper");
      const $placePidsSelect = $placePidsWrapper.find(
        ".select2#alert_place_pids"
      );
      const placePidsSelect2 = $placePidsSelect.select2({
        minimumInputLength: 2,
        placeholder: "Région, département ou ville",
        language: {
          inputTooShort: () => {
            return "Saisissez 2 caractères";
          },
          searching: () => {
            return "Recherche en cours...";
          },
        },
        dropdownParent: $placePidsWrapper,
        ajax: {
          url: searchPlacesURL,
          delay: 250,
          data: (params) => {
            const query = {
              text: params.term,
            };
            return query;
          },
          processResults: (data) => {
            const hits = data.hits;
            let res = [];

            $.each(hits, (key, values) => {
              let tmp = { text: key, children: [] };
              $.each(values, (index, val) => {
                tmp["children"].push({ id: val["pid"], text: val["name"] });
              });
              res.push(tmp);
            });

            return {
              results: res,
            };
          },
        },
      });

      placePidsSelect2
        .data("select2")
        .$container.find(".select2-selection")
        .removeClass("select2-selection--single")
        .addClass("custom-select");

      // Min rooms
      let minRooms = new Cleave("#alert_min_rooms", {
        numeral: true,
        numeralDecimalMark: "",
        delimiter: "",
        numeralPositiveOnly: true,
      });

      // Min bedrooms
      let minBedrooms = new Cleave("#alert_min_bedrooms", {
        numeral: true,
        numeralDecimalMark: "",
        delimiter: "",
        numeralPositiveOnly: true,
      });

      // Min Area
      let minArea = new Cleave("#alert_min_area", {
        numeral: true,
        numeralDecimalMark: ",",
        delimiter: "",
        numeralPositiveOnly: true,
      });

      // Max Price
      let maxPrice = new Cleave("#alert_max_price", {
        numeral: true,
        numeralDecimalMark: "",
        delimiter: " ",
        numeralPositiveOnly: true,
      });

      // Show form
      $form.removeClass("d-none");
    };

    buildAlertForm();

    if (alertCreated == "true") {
      bootbox.alert({
        className: "bootbox-modal bootbox-alert",
        centerVertical: true,
        backdrop: true,
        message: `<div class="text-center">
                    <div class="fs-2">
                      <i class="mdi mdi-check-circle text-success"></i>
                    </div>
                    <h3 class="fs-4 mb-2">Alerte créée</h3>
                    <p>Votre alerte a été créée avec succès. Nous vous enverrons un email dès qu'un bien vérifiera vos critères de recherche.</p>
                  </div>`,
        closeButton: false,
        buttons: {
          ok: {
            label: "OK",
            className: "btn-secondary px-5 mx-auto mb-2",
          },
        },
      });
    }
  }
});
