import Cookies from "js-cookie";

$(() => {
  const $cookiesConsentBox = $(".cookies-consent-box");
  const $cookiesConsentModal = $(".modal#consent-modal");

  // Consent methods
  const rejectAllCookies = () => {
    let consent = Cookies.get("CONSENT");

    const hash = {
      completed: true,
      ts: Date.now(),
      categories: {
        analytics: false,
        ads: false,
      },
    };

    Cookies.set("CONSENT", JSON.stringify(hash), { expires: 365 });

    if ($cookiesConsentModal.length) {
      $cookiesConsentModal.modal("hide");
    }
    if ($cookiesConsentBox.length) {
      $cookiesConsentBox.hide();
    }
    if (consent !== undefined) {
      document.location.reload();
    } else {
      const event = new Event("cookies:defined");
      document.dispatchEvent(event);
    }
  };

  const acceptAllCookies = () => {
    let consent = Cookies.get("CONSENT");

    const hash = {
      completed: true,
      ts: Date.now(),
      categories: {
        analytics: true,
        ads: true,
      },
    };

    Cookies.set("CONSENT", JSON.stringify(hash), { expires: 365 });

    if ($cookiesConsentModal.length) {
      $cookiesConsentModal.modal("hide");
    }
    if ($cookiesConsentBox.length) {
      $cookiesConsentBox.hide();
    }

    if (consent !== undefined) {
      document.location.reload();
    } else {
      const event = new Event("cookies:defined");
      document.dispatchEvent(event);
    }
  };

  const saveSelectedCookies = () => {
    let consent = Cookies.get("CONSENT");

    const hash = {
      completed: true,
      ts: Date.now(),
      categories: {
        analytics: $cookiesConsentModal
          .find(".form-check-input#consent_analytics")
          .is(":checked"),
        ads: $cookiesConsentModal
          .find(".form-check-input#consent_ads")
          .is(":checked"),
      },
    };

    Cookies.set("CONSENT", JSON.stringify(hash), { expires: 365 });

    if ($cookiesConsentModal.length) {
      $cookiesConsentModal.modal("hide");
    }
    if ($cookiesConsentBox.length) {
      $cookiesConsentBox.hide();
    }

    if (consent !== undefined) {
      document.location.reload();
    } else {
      const event = new Event("cookies:defined");
      document.dispatchEvent(event);
    }
  };

  // Consent box
  const buildConsentBox = () => {
    $cookiesConsentBox.find(".btnConsentBoxRejectAll").on("click", (e) => {
      rejectAllCookies();
    });

    $cookiesConsentBox.find(".btnConsentBoxAcceptAll").on("click", (e) => {
      acceptAllCookies();
    });
  };

  const showConsentBox = () => {
    setTimeout(() => {
      $cookiesConsentBox.fadeIn();
    }, 500);
  };

  let consent = Cookies.get("CONSENT");
  if (consent === undefined) {
    buildConsentBox();
    showConsentBox();
  }

  // Consent Modal
  $cookiesConsentModal.find(".category-wrapper .type").on("click", (e) => {
    e.preventDefault();
    $(e.currentTarget).parents(".category-wrapper").toggleClass("collapsed");
  });

  $cookiesConsentModal.on("show.bs.modal", () => {
    $("body").addClass("consent-modal-open");
    const consent = Cookies.get("CONSENT");
    let categories = {};
    if (consent === undefined) {
      categories = {
        analytics: false,
        ads: false,
      };
    } else {
      categories = JSON.parse(consent).categories;
    }
    $cookiesConsentModal
      .find(".form-check-input#consent_analytics")
      .attr("checked", categories.analytics);
    $cookiesConsentModal
      .find(".form-check-input#consent_ads")
      .attr("checked", categories.ads);
  });

  $cookiesConsentModal.on("hide.bs.modal", () => {
    $("body").removeClass("consent-modal-open");
  });

  $cookiesConsentModal.find(".btnConsentModalAcceptAll").on("click", (e) => {
    acceptAllCookies();
  });

  $cookiesConsentModal.find(".btnConsentModalRejectAll").on("click", (e) => {
    rejectAllCookies();
  });

  $cookiesConsentModal
    .find(".btnConsentModalSaveSelection")
    .on("click", (e) => {
      saveSelectedCookies();
    });
});
