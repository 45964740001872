import lightGallery from "lightgallery";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import mapboxgl from "!mapbox-gl";
import Swiper, { Navigation, FreeMode, Mousewheel } from "swiper";

$(() => {
  if ($("body").hasClass("properties") && $("body").hasClass("show")) {
    Swiper.use([Navigation, FreeMode, Mousewheel]);
    mapboxgl.accessToken =
      "pk.eyJ1IjoiYWxkaW1tbyIsImEiOiJjamh2cTVxcm4wd3Q2M3Jxa3N0dDMxbThkIn0.rrCseKZtVNHzJLRUBNXy3w";
    const $leadModal = $(".modal#lead-modal");
    const $leadForm = $leadModal.find("form.lead-form");
    const $leadFormBtn = $leadForm.find(".btn-submit");

    const buildMainGallery = () => {
      lightGallery(document.getElementById("lg-main"), {
        plugins: [lgZoom, lgThumbnail],
        mode: "lg-slide",
        speed: 500,
        loop: false,
        slideEndAnimation: false,
        licenseKey: "3313B03C-32E04EB5-829DA18A-AED95D87",
      });

      const openMainGallery = () => {
        $("#lg-main img:first").trigger("click");
      };

      $("section.cover .cover-wrapper").on("click", (event) => {
        event.preventDefault();
        openMainGallery();
      });
    };

    const buildPlansGallery = () => {
      lightGallery(document.getElementById("lg-plans"), {
        plugins: [lgZoom, lgThumbnail],
        mode: "lg-slide",
        speed: 500,
        loop: false,
        slideEndAnimation: false,
        licenseKey: "3313B03C-32E04EB5-829DA18A-AED95D87",
      });
    };

    const buildMap = () => {
      const center = [devLng, devLat];

      const map = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/aldimmo/cjhw8e8x608b32st231gihdb7",
        center: center,
        zoom: 16,
        interactive: true,
        scrollZoom: false,
      });

      map.addControl(new mapboxgl.NavigationControl(), "bottom-right");
      map.addControl(new mapboxgl.FullscreenControl());

      const el = document.createElement("div");
      el.id = "marker";
      new mapboxgl.Marker(el).setLngLat(center).addTo(map);

      map.on("load", (e) => {
        map.resize();
      });
    };

    const buildLatestPropertiesSlider = () => {
      new Swiper("section.latest-properties .swiper", {
        slidesPerView: 1.5,
        spaceBetween: 20,
        freeMode: true,
        mousewheel: {
          forceToAxis: true,
        },
        navigation: {
          nextEl: "section.latest-properties .nav-btn-next",
          prevEl: "section.latest-properties .nav-btn-prev",
        },
        breakpoints: {
          768: {
            slidesPerView: 2.5,
          },
          992: {
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
        },
      });
    };

    const buildLeadForm = () => {
      $leadForm.validate({
        ignoreTitle: true,
        rules: {
          "buy_lead[name]": { required: true },
          "buy_lead[email]": { required: true, email: true },
          "buy_lead[phone]": { required: true },
        },
        messages: {
          "buy_lead[name]": {
            required: "Veuillez saisir votre nom",
          },
          "buy_lead[email]": {
            required: "Veuillez saisir votre e-mail",
            email: "E-mail non valide",
          },
          "buy_lead[phone]": {
            required: "Veuillez saisir votre téléphone",
          },
        },
        errorElement: "label",
        errorPlacement: (error, element) => {
          var fg = $(element).closest(".form-group");
          $(fg).addClass("has-error");
          $(fg).append(error);
        },
        success: (label, element) => {
          var fg = $(element).closest(".form-group");
          $(fg).removeClass("has-error");
        },
        submitHandler: (form) => {
          $leadFormBtn.addClass("loading").attr("disabled", true);

          const leadData = {
            property_id: propertyID,
            name: $leadForm.find("[name='buy_lead[name]']").val(),
            email: $leadForm.find("[name='buy_lead[email]']").val(),
            phone: $leadForm.find("[name='buy_lead[phone]']").val(),
            message: $leadForm.find("[name='buy_lead[message]']").val(),
            callback: $leadForm
              .find("[name='buy_lead[callback]']")
              .is(":checked"),
            ssb: $leadForm.find("[name='buy_lead[ssb]']").val(),
          };

          $.ajax({
            url: leadsURL,
            type: "POST",
            data: { buy_lead: leadData },
            dataType: "json",
            success: (data, status, xhr) => {
              $leadModal.find(".step-message .content").html(successTpl());
              setTimeout(() => {
                $leadModal.find(".step-form").hide();
                $leadFormBtn.removeClass("loading").attr("disabled", false);
                $leadModal.find(".step-message").show();
              }, 250);
            },
            error: (res) => {
              $leadModal.find(".step-message .content").html(errorTpl());
              setTimeout(() => {
                $leadModal.find(".step-form").hide();
                $leadFormBtn.removeClass("loading").attr("disabled", false);
                $leadModal.find(".step-message").show();
              }, 250);
            },
          });
        },
        invalidHandler: (event, validator) => {
          $leadFormBtn.removeClass("loading").attr("disabled", false);
        },
      });

      $leadForm.removeClass("d-none");

      $leadModal.on("hidden.bs.modal", (e) => {
        $leadForm.find("[name='buy_lead[message]']").val("");
        $leadForm.find("[name='buy_lead[callback]']").prop("checked", false);

        $leadModal.find(".step-form").show();
        $leadModal.find(".step-message").hide();
        $leadModal.find(".step-message .content").html("");

        $leadForm.find(".form-group.has-error").removeClass("has-error");
        $leadForm.find(".form-control.error").removeClass("error");
        $leadForm.find(".form-group label.error").remove();
        $leadFormBtn.removeClass("loading").attr("disabled", false);
      });
    };

    const successTpl = () => {
      return `
        <div class="fs-2">
          <i class="mdi mdi-check-circle text-success"></i>
        </div>
        <h3 class="h5">Demande envoyée</h3>
        <p>Votre demande a été envoyée avec succès. Un consultant de Revente-VEFA vous contactera rapidement.</p>
        <button class="btn btn-secondary mb-2" data-bs-dismiss="modal">Fermer</button>
      `;
    };

    const errorTpl = () => {
      return `
        <div class="fs-2">
          <i class="mdi mdi-alert-circle text-danger"></i>
        </div>
        <h3 class="h5">Une erreur est survenue!</h3>
        <p>Votre demande n'a pas été envoyée car une erreur est survenue sur nos serveurs. Veuillez essayer de nouveau. Contactez-nous si cette erreur persiste.</p>
        <button class="btn btn-secondary mb-2" data-bs-dismiss="modal">Fermer</button>
      `;
    };

    buildMainGallery();
    buildPlansGallery();
    buildMap();

    if ($("section.latest-properties").length > 0) {
      buildLatestPropertiesSlider();
    }

    buildLeadForm();

    $(".leadModalBtn").on("click", (e) => {
      e.preventDefault();
      $leadForm
        .find("[name='buy_lead[message]']")
        .val(
          "Bonjour,\nJ'aimerais avoir plus de renseignements sur ce bien.\nMerci de me recontacter à cet effet.\nCordialement"
        );
      $leadModal.modal("show");
    });
  }
});
