$(() => {
  if ($("body").hasClass("contact") && $("body").hasClass("index")) {
    const $contactForm = $("form.contact-form");
    const $contactFormSubmitBtn = $contactForm.find(".btn-submit");

    const buildContactForm = () => {
      $contactForm.validate({
        ignoreTitle: true,
        rules: {
          "message[subject]": { required: true },
          "message[first_name]": { required: true },
          "message[last_name]": { required: true },
          "message[email]": { required: true, email: true },
          "message[body]": { required: true },
        },
        messages: {
          "message[subject]": {
            required: "Veuillez choisir un objet",
          },
          "message[first_name]": {
            required: "Veuillez saisir votre prénom",
          },
          "message[last_name]": {
            required: "Veuillez saisir votre nom",
          },
          "message[email]": {
            required: "Veuillez saisir votre e-mail",
            email: "E-mail non valide",
          },
          "message[body]": {
            required: "Veuillez saisir votre message",
          },
        },
        errorElement: "label",
        errorPlacement: (error, element) => {
          var fg = $(element).closest(".form-group");
          $(fg).addClass("has-error");
          $(fg).append(error);
        },
        success: (label, element) => {
          var fg = $(element).closest(".form-group");
          $(fg).removeClass("has-error");
        },
        submitHandler: (form) => {
          $contactForm.find(".error-msg").html("");
          $contactFormSubmitBtn.addClass("loading").attr("disabled", true);
          form.submit();
        },
        invalidHandler: (event, validator) => {
          $contactFormSubmitBtn.removeClass("loading").attr("disabled", false);
        },
      });

      // Subject select
      const $subjectSelectWrapper = $contactForm.find(
        ".subject-select-wrapper"
      );
      const $subjectSelect = $subjectSelectWrapper.find(".select2");
      const subjectSelect2 = $subjectSelect
        .select2({
          minimumResultsForSearch: Infinity,
          placeholder: "Choisir",
          dropdownParent: $subjectSelectWrapper,
          allowClear: true,
        })
        .on("select2:unselecting", (event) => {
          $(event.currentTarget).data("unselecting", true);
        })
        .on("select2:opening", (event) => {
          const $this = $(event.currentTarget);
          if ($this.data("unselecting")) {
            $this.removeData("unselecting");
            event.preventDefault();
          }
        });

      subjectSelect2
        .data("select2")
        .$container.find(".select2-selection")
        .removeClass("select2-selection--single")
        .addClass("custom-select");

      $subjectSelect.on("change", (event) => {
        const $this = $(event.currentTarget);
        $this.closest(".form-group").addClass("has-error");
        $this.valid();
      });

      $contactForm.removeClass("d-none");
    };

    buildContactForm();

    if (messageSent == "true") {
      bootbox.alert({
        className: "bootbox-modal bootbox-alert",
        centerVertical: true,
        backdrop: true,
        message: `<div class="text-center">
                    <div class="fs-2">
                      <i class="mdi mdi-check-circle text-success"></i>
                    </div>
                    <h3 class="fs-4 mb-2">Message envoyé</h3>
                    <p>Nous avons bien reçu votre message.<br>Nous vous contacterons dans les plus brefs délais.</p>
                  </div>`,
        closeButton: false,
        buttons: {
          ok: {
            label: "OK",
            className: "btn-secondary px-5 mx-auto mb-2",
          },
        },
      });
    }
  }
});
