import Swiper, { Navigation, FreeMode, Mousewheel } from "swiper";

$(() => {
  if ($("body").hasClass("pages") && $("body").hasClass("home")) {
    Swiper.use([Navigation, FreeMode, Mousewheel]);

    const buildSalesPropertiesSlider = () => {
      new Swiper("section.sales-properties .swiper", {
        slidesPerView: 1.5,
        spaceBetween: 20,
        freeMode: true,
        mousewheel: {
          forceToAxis: true,
        },
        navigation: {
          nextEl: "section.sales-properties .nav-btn-next",
          prevEl: "section.sales-properties .nav-btn-prev",
        },
        breakpoints: {
          768: {
            slidesPerView: 2.5,
          },
          992: {
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
        },
      });
    };

    const buildSoldPropertiesSlider = () => {
      new Swiper("section.sold-properties .swiper", {
        slidesPerView: 1.5,
        spaceBetween: 20,
        freeMode: true,
        mousewheel: {
          forceToAxis: true,
        },
        navigation: {
          nextEl: "section.sold-properties .nav-btn-next",
          prevEl: "section.sold-properties .nav-btn-prev",
        },
        breakpoints: {
          768: {
            slidesPerView: 2.5,
          },
          992: {
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
        },
      });
    };

    const buildArticlesSlider = () => {
      new Swiper("section.blog .swiper", {
        slidesPerView: 1.5,
        spaceBetween: 20,
        freeMode: true,
        mousewheel: {
          forceToAxis: true,
        },
        navigation: {
          nextEl: "section.blog .nav-btn-next",
          prevEl: "section.blog .nav-btn-prev",
        },
        breakpoints: {
          768: {
            slidesPerView: 2.5,
          },
          992: {
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
        },
      });
    };

    const buildTestimonialsSlider = () => {
      new Swiper("section.testimonials .swiper", {
        slidesPerView: 1.5,
        spaceBetween: 20,
        freeMode: true,
        mousewheel: {
          forceToAxis: true,
        },
        navigation: {
          nextEl: "section.testimonials .nav-btn-next",
          prevEl: "section.testimonials .nav-btn-prev",
        },
        breakpoints: {
          768: {
            slidesPerView: 2.5,
          },
          992: {
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
        },
      });
    };

    buildSalesPropertiesSlider();
    buildSoldPropertiesSlider();
    buildTestimonialsSlider();
    buildArticlesSlider();
  }
});
