import mapboxgl from "!mapbox-gl";

$(() => {
  if ($("body").hasClass("properties") && $("body").hasClass("index")) {
    const $filtersModal = $(".modal#filters-modal");
    const $filtersForm = $filtersModal.find("form#filters-modal-form");
    const $filtersFormSubmitBtn = $filtersForm.find("[type=submit]");
    const $placePidsWrapper = $filtersForm.find(".place-pids-wrapper");
    const $placePidsSelect = $placePidsWrapper.find(
      ".select2#search_place_pids"
    );
    let minRoomsInput;
    let minBedroomsInput;
    let minAreaInput;
    let maxPriceInput;

    const $sortOrderSelectWrapper = $(".sort-wrapper");
    const $sortOrderSelect = $sortOrderSelectWrapper.find(".select2");

    mapboxgl.accessToken =
      "pk.eyJ1IjoiYWxkaW1tbyIsImEiOiJjamh2cTVxcm4wd3Q2M3Jxa3N0dDMxbThkIn0.rrCseKZtVNHzJLRUBNXy3w";
    let mapBuilt = false;

    const buildSearchFilters = () => {
      $filtersForm.validate({
        ignoreTitle: true,
        rules: {},
        messages: {},
        errorElement: "label",
        errorPlacement: (error, element) => {
          var fg = $(element).closest(".form-group");
          $(fg).addClass("has-error");
          $(fg).append(error);
        },
        success: (label, element) => {
          var fg = $(element).closest(".form-group");
          $(fg).removeClass("has-error");
        },
        submitHandler: (form) => {
          $filtersFormSubmitBtn.addClass("loading").attr("disabled", true);

          const filters = getFilters();

          if (viewMode === "map") {
            filters["map_view"] = "1";
          }

          let url = propertiesURL;
          if (!$.isEmptyObject(filters)) {
            let queryParams = $.param(filters);
            url = url + "?" + queryParams;
          }
          window.location = url;
        },
        invalidHandler: (event, validator) => {
          $filtersFormSubmitBtn.removeClass("loading").attr("disabled", false);
        },
      });

      const placePidsSelect2 = $placePidsSelect.select2({
        minimumInputLength: 2,
        placeholder: "Région, département ou ville",
        language: {
          inputTooShort: () => {
            return "Saisissez 2 caractères";
          },
          searching: () => {
            return "Recherche en cours...";
          },
        },
        dropdownParent: $placePidsWrapper,
        ajax: {
          url: searchPlacesURL,
          delay: 250,
          data: (params) => {
            const query = {
              text: params.term,
            };
            return query;
          },
          processResults: (data) => {
            const hits = data.hits;
            let res = [];

            $.each(hits, (key, values) => {
              let tmp = { text: key, children: [] };
              $.each(values, (index, val) => {
                tmp["children"].push({ id: val["pid"], text: val["name"] });
              });
              res.push(tmp);
            });

            return {
              results: res,
            };
          },
        },
      });

      placePidsSelect2
        .data("select2")
        .$container.find(".select2-selection")
        .removeClass("select2-selection--single")
        .addClass("custom-select");

      minRoomsInput = new Cleave("#search_min_rooms", {
        numeral: true,
        numeralDecimalMark: "",
        delimiter: "",
        numeralPositiveOnly: true,
      });

      // Min bedrooms
      minBedroomsInput = new Cleave("#search_min_bedrooms", {
        numeral: true,
        numeralDecimalMark: "",
        delimiter: "",
        numeralPositiveOnly: true,
      });

      // Min Area
      minAreaInput = new Cleave("#search_min_area", {
        numeral: true,
        numeralDecimalMark: ",",
        delimiter: "",
        numeralPositiveOnly: true,
      });

      // Max Price
      maxPriceInput = new Cleave("#search_max_price", {
        numeral: true,
        numeralDecimalMark: "",
        delimiter: " ",
        numeralPositiveOnly: true,
      });
    };

    const buildSorting = () => {
      if ($sortOrderSelectWrapper.length) {
        const sortOrderSelect2 = $sortOrderSelect.select2({
          minimumResultsForSearch: Infinity,
        });

        sortOrderSelect2
          .data("select2")
          .$container.find(".select2-selection")
          .removeClass("select2-selection--single")
          .addClass("custom-select");

        $sortOrderSelectWrapper.removeClass("d-none");

        $sortOrderSelect.on("change", (e) => {
          const filters = getFilters();

          filters["sort_order"] = $sortOrderSelect.val();

          if (viewMode === "map") {
            filters["map_view"] = "1";
          }

          let url = propertiesURL;
          if (!$.isEmptyObject(filters)) {
            let queryParams = $.param(filters);
            url = url + "?" + queryParams;
          }
          window.location = url;
        });
      }
    };

    const getFilters = () => {
      const filters = {};

      // Locations
      const placePids = $placePidsSelect.select2("data");
      if (placePids.length > 0) {
        filters["pids"] = [];
        for (const index in placePids) {
          filters["pids"].push(placePids[index]["id"]);
        }
      }

      // Other filters
      let minRoomsValue = minRoomsInput.getRawValue();
      if (minRoomsValue) {
        filters["min_rooms"] = minRoomsValue;
      }

      let minBedroomsValue = minBedroomsInput.getRawValue();
      if (minBedroomsValue) {
        filters["min_bedrooms"] = minBedroomsValue;
      }

      let minAreaValue = minAreaInput.getRawValue();
      if (minAreaValue) {
        filters["min_area"] = minAreaValue;
      }

      let maxPriceValue = maxPriceInput.getRawValue();
      if (maxPriceValue) {
        filters["max_price"] = maxPriceValue;
      }

      return filters;
    };

    // Build the map

    const buildPopupMarkup = (location) => {
      let markup = `<div class="map-properties-list">`;
      location.properties.forEach((property) => {
        const tmp = `
          <a href="${property.permalink}" class="card card-property-map" target="_blank" rel="noopener noreferrer">
            <div class="row g-0">
              <div class="d-none d-md-block col-md-5 col-cover" style="background-image: url('${property.coverURL}')"></div>
              <div class="col-md-7">
                <div class="card-body">
                  <div class="fs-6 fw-600">${property.city}</div>
                  <div class="mt-1">${property.propType}</div>
                  <div class="">${property.numRooms}p • ${property.area}</div>
                  <div class="fs-6 fw-600 mt-1 mb-1">${property.price}</div>
                </div>
              </div>
            </div>
          </a>
        `;
        markup += tmp;
      });

      markup += `</div>`;
      return markup;
    };

    const buildMap = () => {
      const center = [1.7191036, 46.71109];
      const map = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/aldimmo/cjhw8e8x608b32st231gihdb7",
        center: center,
        zoom: 5,
        interactive: true,
        scrollZoom: false,
      });

      map.addControl(new mapboxgl.NavigationControl(), "bottom-right");
      map.addControl(new mapboxgl.FullscreenControl());

      if (mapData.length > 0) {
        let bounds = new mapboxgl.LngLatBounds();

        // add markers
        mapData.forEach((location) => {
          const propLngLat = [location.longitude, location.latitude];
          const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
            buildPopupMarkup(location)
          );
          const el = document.createElement("div");
          el.id = "marker";
          new mapboxgl.Marker(el)
            .setLngLat(propLngLat)
            .setPopup(popup)
            .addTo(map);

          bounds.extend(propLngLat);
        });

        map.fitBounds(bounds, { padding: 100, maxZoom: 11 });
      }

      map.on("load", (e) => {
        map.resize();
      });

      mapBuilt = true;
    };

    buildSearchFilters();

    if (viewMode === "grid") {
      buildSorting();
    }

    if (viewMode === "map") {
      buildMap();
    }
  }
});
